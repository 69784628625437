import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from "react-bootstrap/Modal"
import { Link } from 'react-router-dom';
import "./navbar.css";
import Button from 'react-bootstrap/esm/Button';
import { useState, useContext } from 'react';
import { CartContext } from '../../Context/CartContext';
import CartProduct from '../Shop/cartProduct';
import GetProductData from '../Shop/getProductData';


export default function Navigation() {
    const cart = useContext(CartContext);
    const [show, setShow] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const handleClose = () => {
        setShow(false);
        setExpanded(false);
    }
    const handleShow = () => setShow(true);

    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    let cartArray = cart.items

    function getTotalCost() {
        let totalCost = 0;
        cartArray.map((cartItem) => {
            totalCost += (GetProductData(cartItem.id).price.$numberDecimal * cartItem.quantity);
            return totalCost;
        });
        return totalCost;
    }

    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {

        setIsActive(current => !current);

    };

    let specialShipping = false;
    for (let i = 0; i < cart.items.length; i++) {
        if (cart.items[i].id === "bundle1" || cart.items[i].id === "bundle2" || cart.items[i].id === "bundle3" || cart.items[i].id === "bundle4") {
            specialShipping = true;
        }

    }

    return (
        <>
            <Navbar sticky="top" className="auto" variant="dark" expand="md" expanded={expanded}>
                <Navbar.Brand as={Link} to="/">
                    Lekka Valley Wines
                </Navbar.Brand>
                <NavbarToggle aria-controls='responsive-navbar-nav'
                    onClick={() => setExpanded(!expanded)}
                />
                <NavbarCollapse id="responsive-navbar-nav">
                    <Nav>
                        <Nav.Link as={Link} to="/Shop" onClick={() => setExpanded(false)}>Shop</Nav.Link>
                        <Nav.Link as={Link} to="/About" onClick={() => setExpanded(false)} >About</Nav.Link>
                        {productsCount === 0 ?
                            <Button className="cartButton" onClick={handleShow}><i className="bi bi-cart"></i></Button>
                            :
                            <Button className="cartButton" onClick={handleShow}><i className="bi bi-cart"></i><span className="cartAmount">{productsCount}</span></Button>
                        }
                    </Nav>
                </NavbarCollapse >
            </Navbar >
            <div style={{ display: isActive ? "none" : "flex" }} className="termsDiv">
                <div style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}>
		Minimum order of 3 bottles is required, and free delivery for orders of 6 bottles or more. <br></br>
                </div>
                <div style={{ width: "5%" }}>
                    <Button className="closeButton" onClick={handleClick}>X</Button>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Shopping Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {productsCount >= 3 || specialShipping ?
                        <>
                            <h2>Items in your cart:</h2>
                            <hr></hr>
                            {cart.items.map((currentProduct, idx) => (
                                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity} ></CartProduct>
                            ))}

                            <h1>Total: £ {getTotalCost().toFixed(2)}</h1>

                            <Link to={`/Checkout`}>
                                <Button className="cartItemsCheckoutButton" onClick={handleClose} >
                                    Checkout
                                </Button>
                            </Link>
                        </>
                        : (productsCount === 0 ?
                            <>
                                <h2>Minimum order of 3 bottles required!</h2>
                            </>
                            :
                            <>
                                <h2>Items in your cart:</h2>
                                <hr></hr>
                                {cart.items.map((currentProduct, idx) => (
                                    <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity} ></CartProduct>
                                ))}
                                <h1>Total: £ {getTotalCost().toFixed(2)}</h1>

                                <h2>Minimum order of 3 bottles required!</h2>

                            </>
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}
