import { useCookies } from "react-cookie";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form"

export default function VerificationCookies() {

    const [cookies, setCookie] = useCookies(["verification"]);
    const [show, setShow] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault()

        setCookie("verification", "true", { path: "/" });
        setShow(false);
    }


    if (cookies.verification === undefined) {
        return (
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
            >
                <br></br>
                <h3>I hereby confirm that: </h3>
                <br></br>
                <Form className="verificationPopup" onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Check
                            className="verificationCheck"
                            required
                            type="checkbox"
                            label="I am over 18 years old."
                        />
                        <br></br>
                        <Form.Check
                            className="verificationCheck"
                            required
                            type="checkbox"
                            label="I am not banned from purchasing alcohol due to a court order."
                        />
                        <br></br>
                        <Form.Check
                            className="verificationCheck"
                            required
                            type="checkbox"
                            label="I accept all essential cookies used on this site."
                        />
                    </Form.Group>
                    <br></br>
                    <Button className="confirmationButton" type="submit">CONFIRM</Button>
                </Form>
            </Modal>
        )
    }

}