import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import getProduct from '../Components/Shop/getProduct';
import "./product.css";

export default function Product() {

    let product = getProduct()
    if (product == null) {
        return <h2>Product is Loading . . .</h2>
    }
    else {
        let bundleHeader
        let bundles = []
        if (product.bundles != null) {
            const length = product.bundles.length
            for (let i = 0; i < length; i++) {
                bundles[i] = product.bundles[i].wine
            }
            bundleHeader = <h4><b>Wines in Bundle</b></h4>
        }
        else {
            bundles = [];
            bundleHeader = null
        }
        let tastingHeader
        if (product.tasting != null) {
            tastingHeader = <h4><b>Tasting Notes</b></h4>
        }
        else {
            tastingHeader = null;
        }
        let pairingHeader
        if (product.pairing != null) {
            pairingHeader = <h4><b>Food Pairings</b></h4>
        }
        else {
            pairingHeader = null;
        }
        let alchoholHeader
        if (product.alchohol != null) {
            alchoholHeader = <h5 className='additionalInfoHeader'> Alchohol by Volume:</h5>
        }
        else {
            alchoholHeader = null;
        }
        let accoladesHeader
        let accolades = []
        if (product.accolades != null) {
            const length = product.accolades.length
            for (let i = 0; i < length; i++) {
                accolades[i] = product.accolades[i].accolade
            }
            accoladesHeader = <h5 className='additionalInfoHeader'> Accolades:</h5>
        }
        else {
            accolades = [];
            accoladesHeader = null
        }
        let historyHeader
        if (product.history != null) {
            historyHeader = <h5 className='additionalInfoHeader'> History:</h5>
        }
        else {
            historyHeader = null;
        }
        let methodHeader
        if (product.method != null) {
            methodHeader = <h5 className='additionalInfoHeader'> Method:</h5>
        }
        else {
            methodHeader = null;
        }

        return (
            <Container className='productContainer'>
                <Row>
                    <Col className="productImgCol">
                        <img
                            className="product-image-landscape"
                            src={product.gallery.close}
                            alt="product landscape"
                        />
                    </Col>
                    <Col className="productCol">
                        <div className='productName'><b>{product.name}</b></div>
                        <div className='productYear'>{product.year}</div>
                        <br></br>
                        <Container className='productInformation'>
                            {bundleHeader}
                            {bundles.map((displayWine, i) => (
                                <li className="accoladeList" key={i}>
                                    {displayWine}
                                </li>
                            ))}
                            {tastingHeader}
                            <p>{product.tasting}</p>
                            {pairingHeader}
                            <p>{product.pairing}</p>
                            <h4><b>Additional Information</b></h4>
                            {alchoholHeader}
                            {product.alchohol}
                            {accoladesHeader}
                            {accolades.map((displayAccolade, i) => (
                                <li className="accoladeList" key={i}>
                                    {displayAccolade}
                                </li>
                            ))}
                            {historyHeader}
                            {product.history}
                            {methodHeader}
                            {product.method}
                        </Container>

                    </Col>
                </Row>
            </Container >
        )
    }
}
