import axios from "axios";
import { useState, useEffect } from "react";

export default function GetProduct() {

    const [isLoading, setLoading] = useState(true);
    const [product, setProduct] = useState();

    let productUrl = (window.location.href).split("/");
    let productId = productUrl[4];
    useEffect(() => {
        axios
            .get("/api/Shop/" + productId)
            .then((res) => {
                setProduct(res.data)
                setLoading(false)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
    }, [productId]);

    if (isLoading) {
        return null
    }
    else {
        return product
    }
}
