import { useState } from "react"
import Container from "react-bootstrap/esm/Container";


const quotes = [
    {
        quote: "Drinking good wine with good food in good company is one of life’s most civilized pleasures.",
        author: "Michael Broadbent",
    },
    {
        quote: "Men are like wine—some turn to vinegar, but the best improve with age.",
        author: "Pope John XXIII",
    },
    {
        quote: "The best way to learn about wine is by drinking.",
        author: "Alexis Lichine",
    },
    {
        quote: "With wine and hope, anything is possible.",
        author: "Spanish proverb",
    },
    {
        quote: "In victory, you deserve Champagne; in defeat you need it.",
        author: "Napoleon Bonaparte",
    },
    {
        quote: "Age and glasses of wine should never be counted.",
        author: "Italian proverb",
    },
    {
        quote: "Wine and friends are a great blend.",
        author: "Ernest Hemingway",
    },
    {
        quote: "A good day starts with good coffee and ends with good wine.",
        author: "Anonymous",
    },
    {
        quote: "Love, like wine, gets better with time.",
        author: "Anonymous",
    },
    {
        quote: "A meal without wine is like a day without sunshine.",
        author: "Jean Anthelme Brillat-Savarin",
    },
    {
        quote: "Wine cheers the sad, revives the old, inspires the young, and makes weariness forget his toil.",
        author: "Lord Byron",
    },
    {
        quote: "Wine makes every meal an occasion, every table more elegant, every day more civilized.",
        author: "Andre Simon",
    },
    {
        quote: "Wine is bottled poetry.",
        author: "Robert Louis Stevenson",
    },
    {
        quote: "A gourmet meal without a glass of wine just seems tragic.",
        author: "Kathy Mattea",
    },
    {
        quote: "Wine makes daily living easier, less hurried, with fewer tensions and more tolerance.",
        author: "Benjamin Franklin",
    },
    {
        quote: "A bottle of wine contains more philosophy than all the books in the world.",
        author: "Louis Pasteur",
    },
    {
        quote: "Penicillin cures, but wine makes people happy.",
        author: "Alexander Fleming",
    },
    {
        quote: "Wine is the most civilized thing in the world.",
        author: "Ernest Hemingway",
    },
    {
        quote: "Beer is made by men, wine by God!",
        author: "Martin Luther",
    },
    {
        quote: "I cook with wine; sometimes I even add it to the food.",
        author: "W.C. Fields",
    },
    {
        quote: "Wine is life.",
        author: "Petronius, Roman Writer",
    },
    {
        quote: "Life is too short to drink bad wine.",
        author: "Anonymous",
    },
    {
        quote: "My only regret in life is that I did not drink more Champagne.",
        author: "John Maynard Keynes",
    },
    {
        quote: "Wine... the intellectual part of the meal.",
        author: "Alexandre Dumas",
    },
    {
        quote: "A bottle of wine begs to be shared; I have never met a miserly wine lover.",
        author: "Clifton Fadiman",
    },
    {
        quote: "If food is the body of good living, wine is its soul.",
        author: "Clifton Fadiman",
    },
    {
        quote: "Pinotage has the heart of a lion and the tongue of a woman; after drinking a certain quantity you can talk forever and fight like the devil!",
        author: "Johan Krige",
    },
    {
        quote: "Wine is the thinking person’s health drink.",
        author: "Dr. Phillip Norrie",
    },
    {
        quote: "Wine is sunlight, held together by water.",
        author: "Galileo Galilei",
    }
]


export default function QuoteGenerator() {


    const [num, setNum] = useState(undefined)
    if (num === undefined) {
        setNum(Math.floor(Math.random() * (28 - 0 + 1)) + 0);
    }
    else {
        return (
            <Container className="box">
                <div className="box-inner">
                    <p className="quote">{quotes[num].quote}</p>
                    <p className="author">- {quotes[num].author}</p>
                </div>
            </Container>
        )
    }

}