import React from "react";
import { Routes, Route } from 'react-router-dom';
import VerificationCookies from "./Components/verificationCookies/verificationCookies";
import 'bootstrap/dist/css/bootstrap.min.css';
import CartProvider from "./Context/CartContext";
import Navigation from "./Components/Navigation/navbar";
import Footer from "./Components/Footer/footer";
import Home from "./Routes/home";
import About from "./Routes/about";
import Shop from "./Routes/shop";
import Product from "./Routes/product";
import Checkout from "./Routes/checkout";
import Success from "./Routes/success";
import './App.css'



export default function App() {

    return (
        <CartProvider>
            <VerificationCookies />
            <div className="app">
                <Navigation />
                <div className="body-container">
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/About" element={<About />} />
                        <Route path="/Shop" element={<Shop />} />
                        <Route path="/Shop/:id" element={<Product />} />
                        <Route path="/Checkout" element={<Checkout />} />
                        <Route path="/Checkout/Success" element={<Success />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </CartProvider>
    );
}