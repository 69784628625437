import axios from "axios";
import { useState, useEffect } from "react";


export default function GetProducts() {

    const [isLoading, setLoading] = useState(true);
    const [product, setProduct] = useState();

    useEffect(() => {
        axios
            .get("/api/Shop")
            .then((res) => {
                setProduct(res.data)
                setLoading(false)
            })
    }, []);

    if (isLoading) {
        return null;
    }
    else {
        return product
    }

}
