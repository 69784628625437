import { React } from "react";
import GetProductData from "../Shop/getProductData";
import Row from "react-bootstrap/esm/Row";

export default function CheckoutCart(props) {
    const id = props.id;
    const quantity = props.quantity;
    const productData = GetProductData(id);


    if (productData !== undefined) {
        return (
            <Row className="checkoutCartItems">
                <h4>{productData.name}</h4>
                <h6>{quantity} x {productData.price.$numberDecimal}</h6>
                <h6>Total: £ {(quantity * productData.price.$numberDecimal).toFixed(2)}</h6>
            </Row>
        )
    }
}