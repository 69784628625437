import Container from "react-bootstrap/esm/Container"
import "./footer.css";

export default function Footer() {
    return (
        <div className="footerMain">
            <Container className="footerContainer">
                <div className="footerInfo">
                    <h1>Lekka Valley Wines</h1>
                    <br></br>
                    <p>Flat 1, Bulrhenny House<br></br>
                        Richmond Hill<br></br>
                        Douglas<br></br>
                        Isle of Man<br></br>
                        IM4 1JH
                    </p>
                </div>
                <div className="footerInfo">
                    <h1>Deliveries</h1>
                    <br></br>
                    <p> Monday - North and West of the Island<br></br>
                        Tuesday - Douglas and Onchan area<br></br>
                        Wednesday - South of the Island<br></br>
                        Thursday - North and West of the Island<br></br>
                        Friday - Douglas and Onchan Area<br></br>
                        Saturday - South of the Island<br></br><br></br>
                        Delivery Times: 10:00 - 18:00 <br></br><br></br>
                        Orders must be placed before 16:00 to be eligible for next day delivery<br></br><br></br>

                    </p>
                </div>
                <div className="footerInfo">
                    <h1>Contact Information</h1>
                    <br></br>
                    <p>Tel: (+44) 07624 226812<br></br>
                        email: admin@lekkawines.co.im<br></br>
                        <a href="https://www.facebook.com/lekkavalleywine"><i style={{ fontSize: "2rem", color: "cornflowerblue", paddingRight: "1rem" }} className="bi bi-facebook"></i></a>
                        <a href="https://www.instagram.com/lekka_valley_wines"><i style={{ fontSize: "2rem", color: "cornflowerblue" }} className="bi bi-instagram"></i></a>
                    </p>
                </div>
            </Container >
        </div >
    )
}