import React from "react";
import "./home.css";
import QuoteGenerator from "../Components/Home/quoteGenerator";

export default function Home() {
    return (
        <div>
            <div className='home-container'>
                <div className="banner-flexbox-container">
                    <div style={{ minWidth: "33.33%", minHeight: "100%" }}>
                        <img src="/Images/Wine_1.jpg" className='w-100' alt="Lekka" />
                    </div>
                    <div style={{ minWidth: "33.33%", minHeight: "100%" }}>
                        <img src="/Images/BannerImg.jpg" className='w-100' alt="Banner" />
                    </div>
                    <div style={{ minWidth: "33.33%", minHeight: "100%" }}>
                        <img src="/Images/Wine_2.jpg" className='w-100' alt="Lekka" />
                    </div>
                </div>
                <div className="quotes" style={{ minWidth: "80%" }}>
                    <QuoteGenerator />
                </div>
                <div className="img-flexbox-container">
                    <div className="lekka1" style={{ minWidth: "25%" }}>
                        <img src="/Images/Lekka_1.jpg" className='w-100' alt="Lekka" />
                    </div>
                    <div className="paragraph-container" style={{ minWidth: "50%" }}>
                        <p>'Lekker' is an Afrikaans word that is usually used to describe enjoyable, tasty or delicious food. However, in the Western Cape, it is colloquially used to describe anything nice or enjoyable and is usually pronounced as <b>Lekka</b>.</p>
                    </div>
                    <div className="lekka2" style={{ minWidth: "25%" }}>
                        <img src="/Images/Lekka_2.jpg" className='w-100' alt="Lekka" />
                    </div>
                </div>
                <div className="img-flexbox-container">
                    <div className="valley1" style={{ minWidth: "25%" }}>
                        <img src="/Images/Valley_1.jpg" className='w-100' alt="Lekka" />
                    </div>
                    <div className="paragraph-container" style={{ minWidth: "50%" }}>
                        <p>The Breede River <b>Valley</b> is an area of magnificent views, panoramic landscapes and towering cliffs. It is where breathtaking beauty, majestical mountains and sweeping valleys meet South Africa’s best wines.</p>
                    </div>
                    <div className="valley2" style={{ minWidth: "25%" }}>
                        <img src="/Images/Valley_2.jpg" className='w-100' alt="Lekka" />
                    </div>
                </div>
            </div>
        </div>
    )
}

