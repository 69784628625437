import Container from 'react-bootstrap/esm/Container';
import React from "react";
import "./about.css"


export default function About() {

    return (
        <Container className='aboutContainer'>
            <h1>ABOUT US:</h1>
            <br></br>
            <p>
                Lekka Valley Wines Ltd is an Isle of Man registered wine import company. The family-run company was founded by local Isle of Man veterinarian, Matie Viljoen and his 2 sons Barend and Petrus. The company will be sourcing their wines from the Breede River Valley, the largest wine-producing area in South Africa. The Viljoen family vineyard is also situated in the Valley and has been run by generations of the Viljoen family for more than a hundred years.
            </p>
            <br></br>
            <img src="/Images/familyImg.jpg" className="familyImg" alt="Family" />
            <br></br>
            <br></br>
            <p>
                The Breede River valley has a wide variety of different biomes, with the western part of the valley being more Mediterranean and the eastern part being more arid. This difference in terroir means that the region is suitable for the cultivation of many different cultivars of grapes producing different types of wines. There are more than 85 wineries in the valley producing over 2000 different wines for us to choose from. The Directors have a keen understanding and insight into the wine industry in the valley and have personal and professional connections with most of the producers and winemakers in the area which puts the company in an excellent position to identify the best wines to import to the Isle of Man. The wines will include some exquisite, award-winning wines but also some Lekka, exciting, new-world wines for enjoyment with friends and family.            
            </p>
            <br></br>
            <img src="/Images/valleyImg.jpg" className='w-100' alt="Valley" />
            <br></br>
            <br></br>
            <p><b>
                The company is VAT and AWRS registered. Restaurants and other interested parties can contact us for our prices and payment terms for wholesale sales.
            </b></p>
            <br></br>
        </Container>
    );
}
