import { React, useContext, useState } from "react";
import { CartContext } from '../Context/CartContext';
import CheckoutCart from "../Components/Checkout/checkoutCart";
import Container from "react-bootstrap/esm/Container";
//import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import PaypalCheckoutButton from "../Components/Checkout/paypalCheckoutButton";
import "./checkout.css";
import CardHeader from "react-bootstrap/esm/CardHeader";

export default function Checkout() {

    let [delivery, setDelivery] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAdress2] = useState('')
    const [area1, setArea1] = useState('')
    const [area2, setArea2] = useState('')
    const [postcode, setPostCode] = useState('')
    const [time, setTime] = useState('')
    const [instructions, setInstructions] = useState('')

    const cart = useContext(CartContext);
    const checkoutTotal = cart.getTotalCost().toFixed(2);
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
    let specialShipping = false;
    for (let i = 0; i < cart.items.length; i++) {
        if (cart.items[i].id === "bundle1" || cart.items[i].id === "bundle2" || cart.items[i].id === "bundle3" || cart.items[i].id === "bundle4") {
            specialShipping = true;
        }

    }
    let shippingCost;
    if (productsCount >= 6 || specialShipping === true) {
        shippingCost = 0.00;
    }
    else {
        shippingCost = 5.00;
    }
    const totalCost = (parseFloat(shippingCost) + parseFloat(checkoutTotal)).toFixed(2);


    const handleSubmit = (event) => {
        event.preventDefault();

        setFirstName(event.target.firstName.value);
        setLastName(event.target.lastName.value);
        setEmail(event.target.email.value);
        setPhone(event.target.phone.value);
        setAddress1(event.target.adress1.value);
        setAdress2(event.target.adress2.value);
        setArea1(event.target.city.value);
        setArea2(event.target.country.value);
        setPostCode(event.target.postcode.value);
        setTime(event.target.time.value);
        setInstructions(event.target.instructions.value);
        setDelivery(true);

    };

    return (

        <Container className="checkoutContainer">
            <Row>
                <Col className="infoCol">
                    <Card className="infoCard">
                        {delivery === false ?
                            <>
                                <CardHeader tag="h5" className="mb-4">Delivery Adress</CardHeader>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Contact Details</Form.Label>
                                        <Form.Control required name="firstName" placeholder="First Name" />
                                        <br></br>
                                        <Form.Control required name="lastName" placeholder="Last Name" />
                                        <br></br>
                                        <Form.Control required name="email" placeholder="Email Adress" />
                                        <br></br>
                                        <Form.Control required name="phone" placeholder="Phone Number" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Adress</Form.Label>
                                        <Form.Control required name="adress1" placeholder="Adress 1" />
                                        <br></br>
                                        <Form.Control name="adress2" placeholder="Adress 2" />
                                        <br></br>
                                        <Form.Control required name="city" placeholder="City" />
                                        <br></br>
                                        <Form.Control name="country" placeholder="Isle of Man" value={"Isle of Man"} disabled />
                                        <br></br>
                                        <Form.Control required name="postcode" placeholder="Post Code" />
                                        <br></br>
                                        <Form.Label>Time and Instructions</Form.Label>
                                        <Form.Select name="time">
                                            <option value="Any Time">Any Time</option>
                                            <option value="10:00 - 11:00">10:00 - 11:00</option>
                                            <option value="11:00 - 12:00">11:00 - 12:00</option>
                                            <option value="12:00 - 13:00">12:00 - 13:00</option>
                                            <option value="13:00 - 14:00">13:00 - 14:00</option>
                                            <option value="14:00 - 15:00">14:00 - 15:00</option>
                                            <option value="15:00 - 16:00">15:00 - 16:00</option>
                                            <option value="16:00 - 17:00">16:00 - 17:00</option>
                                            <option value="17:00 - 18:00">17:00 - 18:00</option>
                                        </Form.Select>
                                        <br></br>
                                        <Form.Control as="textarea" name="instructions" placeholder="Delivery instructions if any" />
                                    </Form.Group>
                                    <Button className="checkoutButton" type="Submit">CONFIRM</Button>
                                </Form>
                                <br></br>
                            </>
                            : productsCount >= 3 || specialShipping === true ?
                                <>
                                    <CardHeader tag="h5" className="mb-4">Delivery Adress</CardHeader>
                                    <div className="deliveryDetails">
                                        <h4>Contact Details:</h4>
                                        <p>
                                            {firstName} {lastName}<br></br>
                                            {email}<br></br>
                                            {phone}
                                        </p>
                                        <h4>Address:</h4>
                                        <p>
                                            {address1}<br></br>
                                            {address2}<br></br>
                                            {area1}<br></br>
                                            {area2}<br></br>
                                            {postcode}
                                        </p>
                                        <h4>Time and Instructions:</h4>
                                        <p>
                                            {time}<br></br>
                                            {instructions}<br></br>
                                        </p>
                                        <Button className="checkoutButton" onClick={() => setDelivery(false)}>EDIT</Button>
                                    </div>

                                    <br></br>
                                    <CardHeader tag="h5" className="mb-4">Payment</CardHeader>
                                    <div className="paymentContainer">
                                        <PaypalCheckoutButton
                                            firstName={firstName}
                                            lastName={lastName}
                                            email={email}
                                            phone={phone}
                                            address1={address1}
                                            address2={address2}
                                            area1={area1}
                                            area2={area2}
                                            postcode={postcode}
                                            time={time}
                                            instructions={instructions} />
                                    </div>
                                    <br></br>
                                </>
                                :
                                <>
                                    <CardHeader tag="h5" className="mb-4">Delivery Adress</CardHeader>
                                    <div className="deliveryDetails">
                                        <h4>Contact Details:</h4>
                                        <p>
                                            {firstName} {lastName}<br></br>
                                            {email}<br></br>
                                            {phone}
                                        </p>
                                        <h4>Address:</h4>
                                        <p>
                                            {address1}<br></br>
                                            {address2}<br></br>
                                            {area1}<br></br>
                                            {area1}<br></br>
                                            {postcode}
                                        </p>
                                        <Button className="checkoutButton" onClick={() => setDelivery(false)}>EDIT</Button>
                                    </div>
                                    <p>A minimum order of 3 bottles is required!</p>
                                </>
                        }
                    </Card>
                </Col>
                <Col className="cartCol">
                    <Card className="cartCard">
                        <CardHeader tag="h5" className="mb-4">Shopping Cart</CardHeader>
                        {cart.items.map((currentProduct, idx) => (
                            <CheckoutCart key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CheckoutCart>
                        ))}
                        <hr></hr>
                        <h3>Subtotal: £ {checkoutTotal}</h3>
                        <h3>Shipping: £ {shippingCost.toFixed(2)}</h3>
                        <br></br>
                        <h3><b>Total: £ {totalCost}</b></h3>
                    </Card>
                </Col>
            </Row>
        </Container >
    )
}

