import Button from 'react-bootstrap/Button';
import { CartContext } from '../../Context/CartContext';
import { useContext } from "react";
import GetProductData from './getProductData';

function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = GetProductData(id);
    let price = productData.price.$numberDecimal


    if (productData !== undefined) {
        return (
            <div className="cartModal">
                <h4>{productData.name}</h4>
                <h6>{quantity} x {price}</h6>
                <h6>Total: £ {(quantity * price).toFixed(2)}</h6>
                <Button onClick={() => cart.addOneToCart(id, price)} className="cartItemsValueButton">+</Button>
                <b>{quantity}</b>
                <Button onClick={() => cart.removeOneFromCart(id)} className="cartItemsValueButton">-</Button><br /><br />
                <Button onClick={() => cart.deleteFromCart(id)} className="cartItemsRemoveButton">Remove</Button>
                <hr></hr>
            </div>
        )
    }
}

export default CartProduct;

