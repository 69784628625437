import Container from 'react-bootstrap/esm/Container';
import React from "react";
import "./success.css"


export default function Success() {

    return (
        <Container className='successContainer' >
            <br></br>
            <h1>Payment Success:</h1>
            <br></br>
            <p className="successPage">
                Your payment for the order was successfully processed.<br></br>
                You will receive an email confirmation for the above-mentioned order shortly.<br></br>
                <br></br>
                While we do attempt to make every delivery at your preferred time, due to time contstraints and the number of other deliveries this might not always be possible.<br></br>
                <br></br>
                Please note that due to Isle of Man legislation and the Challenge 25 policy we reserve the right to request that any person recieving a delivery from us, provide a proper form of identification upon receipt of the delivery.<br></br>

            </p>

        </Container>
    );
}
